<template>
    <div v-if="isDataLoaded" class="list-final-spiele">
        <div v-if="spiele.length == 0">
            <md-empty-state
                md-icon="reduce_capacity"
                :md-label="lang.turnierplan.emptyStateLabel"
                :md-description="lang.turnierplan.emptyStateDescription">
            </md-empty-state>
        </div>
        <div v-else class="list-spiele-container">
            <ul class="ul-unstyled spieleList">
                <li v-for="(spiel, index) in spiele" :key="spiel.spielID">
                    <span class="spielArtHeadline" v-if="index == 0">{{getSpielArtBezeichnung(spiel.spielArt.spielArtEnum)}}</span>
                    <span class="spielArtHeadline" 
                        v-if=" index != 0 && spiele[index].spielArt.spielArtEnum != spiele[index-1].spielArt.spielArtEnum">{{getSpielArtBezeichnung(spiel.spielArt.spielArtEnum)}}</span>
                    <span class="tischHeadline" 
                        v-if="index == 0 || index != 0 && spiele[index].tisch != spiele[index-1].tisch || spiele[index].spielArt.spielArtEnum != spiele[index-1].spielArt.spielArtEnum || spiel.spielArt.spielArtEnum === SPIELART.FINALE.name || spiel.spielArt.spielArtEnum === SPIELART.FINALE_PLATZ3.name">
                            {{lang.spieleList.tischHeadline}} {{spiel.tisch}}
                    </span>
                    <div class="spiel-item card">
                        <span class="spielNummer">{{spiel.tischSpielNummer}}</span>
                        <div v-if="spiel.spielErgebnisse.length != 0">
                            <div class="teamName team1">
                                <span :class="spiel.spielErgebnisse[0].gewonnen ? 'gewonnen' : ''">{{getTeamNameByTeamID(spiel.spielErgebnisse[0].spielTeamID.teamID)}}</span>
                            </div>
                            <div class="punkte">
                                <span :class="spiel.spielErgebnisse[0].gewonnen ? 'gewonnen' : ''">{{spiel.spielErgebnisse[0].erreichtePunkte}}</span>
                                <span>-</span>
                                <span :class="spiel.spielErgebnisse[1].gewonnen ? 'gewonnen' : ''">{{spiel.spielErgebnisse[1].erreichtePunkte}}</span>
                            </div>
                            <div class="teamName team2">
                                <span :class="spiel.spielErgebnisse[1].gewonnen ? 'gewonnen' : ''">{{getTeamNameByTeamID(spiel.spielErgebnisse[1].spielTeamID.teamID)}}</span>
                            </div>
                            <div v-if="!isTurnierBeendet" class="button-group-game">
                                <div v-if="isSpielerTurnierAdmin && !(spiel.spielErgebnisse[0].spielTeamID.teamID == teamIDByPlayer || spiel.spielErgebnisse[1].spielTeamID.teamID == teamIDByPlayer)">
                                    <md-button class="md-primary md-round md-raised md-filled md-secondary" 
                                        @click="showDialogSaveSpielstand=true,
                                                setNewSpielstand(spiel.spielID,
                                                    spiel.spielErgebnisse[0].erreichtePunkte,
                                                    spiel.spielErgebnisse[1].erreichtePunkte,
                                                    spiel.spielErgebnisse[0].spielTeamID.teamID,
                                                    spiel.spielErgebnisse[1].spielTeamID.teamID)">
                                        <i class="fas fa-pencil-alt"></i>
                                    </md-button>
                                </div>
                                <div v-if="spiel.spielErgebnisse[0].spielTeamID.teamID == teamIDByPlayer || spiel.spielErgebnisse[1].spielTeamID.teamID == teamIDByPlayer || (isSpielerTurnierAdmin && teamIDByPlayer == 0)">
                                    <div v-if="spiel.spielErgebnisse[0].gewonnen || spiel.spielErgebnisse[1].gewonnen">
                                        <md-button class="md-primary md-round md-raised md-filled md-secondary" 
                                            v-if="!isSpielerTurnierAdmin"
                                            @click="showDialogSaveSpielstand=true,
                                                    setNewSpielstand(spiel.spielID,
                                                        spiel.spielErgebnisse[0].erreichtePunkte,
                                                        spiel.spielErgebnisse[1].erreichtePunkte,
                                                        spiel.spielErgebnisse[0].spielTeamID.teamID,
                                                        spiel.spielErgebnisse[1].spielTeamID.teamID)">
                                            <i class="fas fa-pencil-alt"></i>
                                        </md-button>
                                    </div>
                                    <md-button v-else @click="spielen(spiel.spielID)" class="md-primary md-round md-raised md-filled md-secondary">
                                        <span>{{lang.spieleList.buttonTextSpielen}}</span>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="teamName team1">
                                <span>{{lang.listFinalSpiele.teamOpen}}</span>
                            </div>
                            <div class="punkte">
                                <span>0</span>
                                <span>-</span>
                                <span>0</span>
                            </div>
                            <div class="teamName team2">
                                <span>{{lang.listFinalSpiele.teamOpen}}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <md-dialog :md-active.sync="showDialogSaveSpielstand" :md-fullscreen=false>
            <div class="dialog-content dialog-spielstand">
                <p class="headline p-unstyled">{{lang.spielstandForm.spielStandEditHeadline}}</p>
                <p class="message p-unstyled">{{lang.spielstandForm.addSpielStandMessage}}</p>
                <p class="headline2">{{lang.spielstandForm.spielStandHeadline2}}</p>
                <form @submit.prevent="editSpielStand()" id="addSpielstandForm">
                    <md-field :class="getValidationClass('erreichtePunkteTeam1')">
                        <label>{{getTeamNameByTeamID(spielstandNew.teamID1)}}</label>
                        <md-input @blur="$v.spielstandNew.erreichtePunkteTeam1.$touch()" type="number" step="1" :min="$v.spielstandNew.erreichtePunkteTeam1.$params.between.min" 
                            :max="$v.spielstandNew.erreichtePunkteTeam1.$params.between.max" id="erreichtePunkteTeam1" v-model="spielstandNew.erreichtePunkteTeam1" :name="lang.spielstandForm.erreichtePunkteTeam1Label"></md-input>
                        <md-icon v-if="isFieldInvalid('erreichtePunkteTeam1')" class="color-invalid">close</md-icon>
                        <span class="md-error" v-if="!$v.spielstandNew.erreichtePunkteTeam1.required">{{lang.spielstandForm.erreichtePunkteTeam1Required}}</span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam1.between">
                            {{$v.spielstandNew.erreichtePunkteTeam1.$params.between.min}}-{{$v.spielstandNew.erreichtePunkteTeam1.$params.between.max}} {{lang.spielstandForm.erreichtePunkteTeam1Between}}
                        </span>
                    </md-field>
                    <md-field :class="getValidationClass('erreichtePunkteTeam2')">
                        <label>{{getTeamNameByTeamID(spielstandNew.teamID2)}}</label>
                        <md-input @blur="$v.spielstandNew.erreichtePunkteTeam2.$touch()" type="number" step="1" :min="$v.spielstandNew.erreichtePunkteTeam2.$params.between.min" 
                            :max="$v.spielstandNew.erreichtePunkteTeam2.$params.between.max" id="erreichtePunkteTeam2" v-model="spielstandNew.erreichtePunkteTeam2" :name="lang.spielstandForm.erreichtePunkteTeam2Label"></md-input>
                        <md-icon v-if="isFieldInvalid('erreichtePunkteTeam2')" class="color-invalid">close</md-icon>
                        <span class="md-error" v-if="!$v.spielstandNew.erreichtePunkteTeam2.required">{{lang.spielstandForm.erreichtePunkteTeam2Required}}</span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam2.between">
                            {{$v.spielstandNew.erreichtePunkteTeam2.$params.between.min}}-{{$v.spielstandNew.erreichtePunkteTeam2.$params.between.max}} {{lang.spielstandForm.erreichtePunkteTeam2Between}}
                        </span>
                        <span class="md-error" v-else-if="!$v.spielstandNew.erreichtePunkteTeam2.notSameValue">{{lang.spielstandForm.erreichtePunkteTeam2NotSameValue}}</span>
                    </md-field>
                    <md-dialog-actions>
                        <md-button @click="showDialogSaveSpielstand=false, $v.$reset()">{{lang.spielstandForm.buttonTextAbbrechen}}</md-button>
                        <md-button type="submit" class="md-primary">{{lang.spielstandForm.buttonTextAgree}}</md-button>
                    </md-dialog-actions>
                </form>
            </div>
        </md-dialog>
        <SubmitModal/>
    </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'
import {required, between, not, sameAs} from 'vuelidate/lib/validators'
import SubmitModal from '../Utils/SubmitModal'
import { VueOfflineMixin } from 'vue-offline'

export default {
    name: 'ListFinalSpieleByTurnier',
    props: ['turnierID'],
    mixins: [VueOfflineMixin],
    components: {
        SubmitModal
    },
    data() {
        return {
            SPIELART: store.getters.getSpielArt,
            selectedGruppenIDs: [],
            selectedTische: [],
            showDialogSaveSpielstand: false,
            spielstandNew: {
                spielID: 0,
                teamID1: 0,
                teamID2: 0,
                erreichtePunkteTeam1: 0,
                erreichtePunkteTeam2: 0
            },
        }
    },
    validations: {
        spielstandNew: {
            erreichtePunkteTeam1: {
                required,
                between: between(0, 10)
            },
            erreichtePunkteTeam2: {
                required,
                between: between(0, 10),
                notSameValue: not(sameAs('erreichtePunkteTeam1'))
            }
        }
    },
    methods: {
        getSpielArtBezeichnung(spielArt) {
            return this.lang.spielart[spielArt]
        },
        getTeamNameByTeamID(teamID) {
            const teamData = store.getters.getTeamByTeamID(teamID);
            return teamData == null || teamData.length == 0 ? "" : teamData[0].team.teamName;
        },
        getValidationClass(fieldName) {
            return {
                'md-invalid': this.isFieldInvalid(fieldName)
            }
        },
        isFieldInvalid(fieldName) {
            const field = this.$v.spielstandNew[fieldName]
            if (field) {
                return field.$invalid && field.$dirty;
            }
        },
        setNewSpielstand(spielID, erreichtePunkteTeam1, erreichtePunkteTeam2, teamID1, teamID2) {
            this.spielstandNew.spielID = spielID,
            this.spielstandNew.teamID1 = teamID1,
            this.spielstandNew.teamID2 = teamID2,
            this.spielstandNew.erreichtePunkteTeam1 = erreichtePunkteTeam1,
            this.spielstandNew.erreichtePunkteTeam2 = erreichtePunkteTeam2
        },
        async editSpielStand() {
            if (this.isOffline) {
                this.showDialogActive(false, this.lang.modal.errorHeadline, this.lang.offline.offlineErrorMessage);
            } else {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    this.$v.$reset();
                    await store.dispatch('putSpielergebnis', this.spielstandNew, this.turnierID)
                        .then(result => {
                            if (result.data != null) {
                                store.dispatch('getSpieleByTurnierID', this.turnierID);
                                this.showDialogActive(true, this.lang.spielstandForm.editSuccessHeadline, "");
                                setTimeout(() => store.commit("SET_SHOW_SUBMIT_MODAL_STATUS", false), store.state.submitModalSuccessSeconds);
                            } else {
                                this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spiele.apiReponseErrorMessage);
                            }
                        })
                        .catch(error => {
                            this.showDialogActive(false, this.lang.modal.errorHeadline, store.state.spiele.apiReponseErrorMessage)
                        });
                }
            }
        },
        showDialogActive(formSuccess, formSuccessHeadline, formSuccessMessage) {
            this.showDialogSaveSpielstand = false;
            store.commit("SET_SHOW_SUBMIT_MODAL", {
                show: true,
                success: formSuccess,
                headline: formSuccessHeadline,
                message: formSuccessMessage
            });
        },
        spielen(spielID) {
            router.push({ name: 'PlayGame', params: { spielID: spielID, turnierID: this.turnierID }});
        },
        isTurnierStarted() {
            const dateNow = new Date();
            const turnierBeginnUhrzeitSplit = store.getters.getTurnier.startZeit.split(":");
            const turnierBeginnDatumSplit = store.getters.getTurnier.datum.split("-");
            const turnierBeginn = new Date(turnierBeginnDatumSplit[0], turnierBeginnDatumSplit[1]-1, turnierBeginnDatumSplit[2],
                                            turnierBeginnUhrzeitSplit[0], turnierBeginnUhrzeitSplit[1], turnierBeginnUhrzeitSplit[2], 0);
            return (turnierBeginn - dateNow) < 0;
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        spielerID() {
            return store.getters.getSpielerID;
        },
        teamIDByPlayer() {
            return store.getters.getPlayerTeamNumber(this.spielerID);
        },
        spiele() {
            return store.getters.getFinalSpiele;
        },
        tische() {
            return store.getters.getTische;
        },
        isDataLoaded() {
            return store.state.spiele.isDataLoaded;
        },
        isSpielerTurnierAdmin() {
            return store.getters.isSpielerTurnierAdmin(this.turnierID);
        },
        isTurnierBeendet() {
            return store.getters.isTurnierBeendet;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>