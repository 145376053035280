<template>
    <div>
        <ListFinalSpieleByTurnier :turnierID="turnierID"/>
    </div>
</template>

<script>
import ListFinalSpieleByTurnier from '@/components/Spiele/ListFinalSpieleByTurnier.vue'

export default {
    name: 'ListFinalSpiele',
    props: ['turnierID'],
    components: {
        ListFinalSpieleByTurnier
    }
}
</script>
